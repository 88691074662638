<template>
  <div class="row">
    <div
      class="col-xxl-4 col-md-6 col-sm-12 mb-2"
      v-for="qr in qrData"
      :key="qr.name"
    >
      <h3 class="QRName">{{ qr.name }}</h3>
      <div
        class="QRCode"
        :style="{ 'background-image': `url(${qr.QRCode})` }"
      ></div>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, reactive, watch } from "vue";
import localstorage from "@/core/services/LocalstorageService";
import { getFacilityUnitByFacility } from "@/core/services/api/facilityUnits";
import { FacilityMode } from "@/core/services/responsesModel/facilityExtend";
import { getQrCodePath } from "@/core/utils/imagePath";

export default defineComponent({
  props: ["facilityData"],
  setup(props) {
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const qrData: any = reactive([]);
    const setFacilityUnits = async () => {
      let facilityGuid = props.facilityData.guid;
      return await getFacilityUnitByFacility(currentSpaceGuid!, facilityGuid);
    };

    const getQRPath = (type, guid) => {
      return getQrCodePath(
        "https://spacesgoapi.azurewebsites.net",
        type,
        guid,
        "false"
      );
    };

    // 取得顯示qrcode
    const getDisplayQRCode = async () => {
      let facility = props.facilityData;
      let facilityUnits = await setFacilityUnits();
      let facilityUnitQR: any = [];

      if (facility.mode == FacilityMode.PrivateOffice) {
        qrData.splice(0, qrData.length, {
          name: "整體",
          QRCode: getQRPath("PrivateOffice", facility.guid),
        });
      } else {
        facilityUnits.forEach((facilityUnit) => {
          facilityUnitQR.push({
            name: facilityUnit.name,
            QRCode: getQRPath("FacilityUnit", facilityUnit.guid),
          });
          qrData.splice(0, qrData.length, ...facilityUnitQR);
        });
      }
    };

    watch(
      props,
      () => {
        if (typeof props.facilityData !== undefined) {
          getDisplayQRCode();
        }
      },
      {
        deep: true,
        immediate: false,
      }
    );

    // const init = async () => {
    //   await getDisplayQRCode();
    // };
    // init();

    return {
      qrData,
    };
  },
});
</script>

<style scoped>
.QRName {
  text-align: center;
  height: 25px;
  line-height: 45px;
  margin: 0px;
}
.QRCode {
  width: 300px;
  height: 300px;
  background-size: 100% 100%;
  margin: auto;
}
</style>