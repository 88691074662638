<template>
  <div class="row mb-5" style="width: 100%">
    <el-form
      id="BasicInformation-facility"
      @submit.prevent="onSubmit()"
      :model="props.facilityData"
      ref="formRef"
      class="form"
      label-width="110px"
    >
      <el-form-item label="顯示排序">
        <el-input-number
          :disabled="!props.isEdit"
          v-model="props.facilityData.displayOrder"
          :min="1"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="目前狀態">
        <el-select
          style="width: 100%"
          v-model="props.facilityData.status"
          :disabled="!props.isEdit"
        >
          <el-option
            v-for="item in statusOptions"
            :key="item.value"
            :label="item.text"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="名稱">
        <el-input
          v-model="props.facilityData.name"
          :disabled="!props.isEdit"
        ></el-input>
      </el-form-item>
      <el-form-item label="類型">
        <el-select
        style="width: 100%"
          v-model="props.facilityData.category"
          :disabled="!props.isEdit"
        >
          <el-option
            v-for="item in categoriesOptions"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="營業時間">
        <el-input
          v-model="props.facilityData.openingHoursDescription"
          :disabled="!props.isEdit"
        ></el-input>
      </el-form-item>
      <el-form-item label="地點">
        <el-input
          v-model="props.facilityData.location"
          :disabled="!props.isEdit"
        ></el-input>
      </el-form-item>
      <el-form-item label="原價標籤">
        <el-input
          v-model="props.facilityData.priceTag"
          :disabled="!props.isEdit"
        ></el-input>
      </el-form-item>
      <el-form-item label="使用須知">
        <el-input
          :disabled="!props.isEdit"
          type="textarea"
          v-model="props.facilityData.usageNotice"
          :autosize="{ minRows: 4, maxRows: 6 }"
        ></el-input>
      </el-form-item>
      <el-form-item label="簡介">
        <el-input
          :disabled="!props.isEdit"
          type="textarea"
          v-model="props.facilityData.description"
          :autosize="{ minRows: 4, maxRows: 6 }"
        ></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script lang='ts'>
import { computed, defineComponent, nextTick, reactive, ref, watch } from "vue";
import localstorage from "@/core/services/LocalstorageService";

export default defineComponent({
  props: ["facilityData", "isEdit"],
  setup(props, { attrs, emit, slots }) {
    //類型options
    const categoriesOptions =
      localstorage.getCurrentSpaces()?.space.facilitySetting.facilityCategories;
    const statusOptions = [
      { value: "Public", text: "開放" },
      { value: "Private", text: "關閉" },
      { value: "Protected", text: "開放(不可預約)" },
    ];
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    let spaceURL = computed(() => {
      return `https://partner.spacesgo.com/#/space/${currentSpaceGuid}`;
    });
    let spaceData: any = reactive({});
    const formRef = ref<null | HTMLFormElement>(null);
    const saveTagInputPhone: any = ref(null);
    const saveTagInputEmail: any = ref(null);
    let inputVisiblePhone = ref(false);
    let inputValuePhone = ref("");
    let inputVisibleEmail = ref(false);
    let inputValueEmail = ref("");
    let onSubmit = () => {
      console.log("submit!");
    };




    return {
      spaceData,
      onSubmit,
      formRef,
      inputVisiblePhone,
      inputValuePhone,
      inputVisibleEmail,
      inputValueEmail,
      spaceURL,
      props,
      statusOptions,
      categoriesOptions,
    };
  },
});
</script>





