<template>
  <div>
    <el-form
      id="kt_modal_additional_orders_list"
      @submit.prevent="submit()"
      :model="formData"
      ref="formRef"
      class="form"
    >
      <div>
        <label class="fs-6 fw-bold mb-2">
          <span>圖示</span>
        </label>
        <el-form-item>
          <el-select v-model="formData.icon" placeholder="請選擇圖示">
            <el-option
              v-for="item in icons"
              :key="item"
              :label="item"
              :value="item"
            >
              <img
                :src="getIcon(item)"
                alt="..."
                style="max-width: 25px; max-hight: 25px"
              />
              {{ item }}
            </el-option>
          </el-select>
        </el-form-item>
        <label class="fs-6 fw-bold mb-2">
          <span>標題</span>
        </label>
        <el-form-item>
          <el-input v-model="formData.title"></el-input>
        </el-form-item>
        <label class="fs-6 fw-bold mb-2">
          <span>內容</span>
        </label>
        <el-form-item>
          <el-input v-model="formData.content"></el-input>
        </el-form-item>
      </div>
      <div>
        <button class="btn btn-success" type="submit">提交</button>
      </div>
    </el-form>
  </div>
</template>

<script lang='ts'>
import { defineComponent, reactive, ref } from "vue";
import { getIcons, getIconPath } from "@/core/utils/imagePath";

export default defineComponent({
  setup(props, { attrs, emit, slots }) {
    let icons = getIcons();
    let formData = reactive({
      icon: "",
      title: "",
      content: "",
    });

    const getIcon = (name) => {
      return getIconPath(name);
    };

    const submit = () => {
      emit('addFacilitiesAndServices',formData)
    }

    return {
      icons,
      getIcon,
      formData,
      submit
    };
  },
});
</script>

<style>
</style>