export function getQrCodePath(url, type, guid, isHash) {
  return `${url}/QrCode/Generate/?type=${type}&guid=${guid}&isHash=${isHash}`
}
export function getImagePath(url, guid) {
  return `${url}/File/Download/?fileGuid=${guid}`
}

const icons = require.context('../../../public/media/amenity', false, /^.*\.svg$/)
const iconsName = icons.keys().map(o => o.replace('./', '').replace('.svg', ''))

export function getIconList() {
  return iconsName
}
export function getIconPath(name) {
  return iconsName.includes(name) ? `/media/amenity/${name}.svg` : '/media/png/default.png'
}
export function getIcons() {
  return iconsName
}
