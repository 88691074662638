
import { defineComponent, reactive, ref, watch } from "vue";
import localstorage from "@/core/services/LocalstorageService";
import BasicInformation from "./components/BasicInformation.vue";
import QrCode from "./components/QrCode.vue";
import FacilitiesAndServices from "./components/FacilitiesAndServices.vue";
import Settings from "./components/Settings.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter, useRoute } from "vue-router";
import { getFacility, updateFacility } from "@/core/services/api/facilities";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";

export default defineComponent({
  components: {
    BasicInformation,
    FacilitiesAndServices,
    Settings,
    QrCode,
  },
  emits: ["changeCapacity"],
  setup() {
    const facilityData = reactive({
      type: "Facility",
      mode: "Standard",
      status: "Public",
      information: [
        {
          icon: "string",
          title: "string",
          content: "string",
        },
      ],
      theme: {
        coverPhoto: "string",
        images: ["string"],
        locationImages: ["string"],
      },
      setting: {
        availableDays: 0,
        invitationNumber: 0,
        minuteToEnter: 0,
        minuteToCancel: 0,
        minuteToExtend: 0,
        minuteToCheckOut: 0,
        autoRelease: true,
        delayCaptureInDays: 0,
        canDoorAccess: true,
        canIotFetch: true,
        canDistribute: true,
        canInvite: true,
        canUserCheckOut: true,
        canUserFeedback: true,
        feedbackBeginTime: "",
        feedbackDuration: 0,
      },
      guid: "string",
      space: "string",
      id: "string",
      name: "string",
      category: "string",
      createTime: "2021-11-10T01:35:14.477Z",
      description: "string",
      openingHoursDescription: "string",
      location: "string",
      quota: 0,
      displayOrder: 0,
      usageNotice: "string",
      priceTag: "string",
    });
    const router = useRouter();
    const route = useRoute();
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    let isEdit = ref(false);
    const spaceData: any = reactive({});
    const facilityGuid = route.params.facilityGuid;

    const setFacilityData = async () => {
      let response: any = await getFacility(facilityGuid);
      Object.assign(facilityData, response.facility);
    };

    const changeCapacity = (capacity) => {
      facilityData.setting.invitationNumber = capacity.value - 1;
    };

    const update = async () => {
      await Swal.fire({
        title: "您確定要提交修改嗎?",
        confirmButtonText: "確定",
        denyButtonText: `取消`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let request = {
            guid: facilityGuid,
            name: facilityData.name,
            category: facilityData.category,
            mode: facilityData.mode,
            status: facilityData.status,
            description: facilityData.description,
            openingHoursDescription: facilityData.openingHoursDescription,
            location: facilityData.location,
            images: facilityData.theme.images,
            information: facilityData.information,
            setting: facilityData.setting,
            displayOrder: facilityData.displayOrder,
            usageNotice: facilityData.usageNotice,
            priceTag: facilityData.priceTag,
          };
          // 可回饋起始時間轉換成Utc時間
          request.setting.feedbackBeginTime = getUtcTimeString(
            request.setting.feedbackBeginTime
          );

          await updateFacility(request);
          Swal.fire("提交成功!", "", "success");
          setFacilityData();
        }
      });
    };

    const init = async () => {
      await setFacilityData();
    };
    init();

    return {
      isEdit,
      facilityData,
      changeCapacity,
      update,
    };
  },
});
