
import { computed, defineComponent, nextTick, reactive, ref, watch } from "vue";
import localstorage from "@/core/services/LocalstorageService";

export default defineComponent({
  props: ["facilityData", "isEdit"],
  setup(props, { attrs, emit, slots }) {
    //類型options
    const categoriesOptions =
      localstorage.getCurrentSpaces()?.space.facilitySetting.facilityCategories;
    const statusOptions = [
      { value: "Public", text: "開放" },
      { value: "Private", text: "關閉" },
      { value: "Protected", text: "開放(不可預約)" },
    ];
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    let spaceURL = computed(() => {
      return `https://partner.spacesgo.com/#/space/${currentSpaceGuid}`;
    });
    let spaceData: any = reactive({});
    const formRef = ref<null | HTMLFormElement>(null);
    const saveTagInputPhone: any = ref(null);
    const saveTagInputEmail: any = ref(null);
    let inputVisiblePhone = ref(false);
    let inputValuePhone = ref("");
    let inputVisibleEmail = ref(false);
    let inputValueEmail = ref("");
    let onSubmit = () => {
      console.log("submit!");
    };




    return {
      spaceData,
      onSubmit,
      formRef,
      inputVisiblePhone,
      inputValuePhone,
      inputVisibleEmail,
      inputValueEmail,
      spaceURL,
      props,
      statusOptions,
      categoriesOptions,
    };
  },
});
