
import { defineComponent, reactive, ref } from "vue";
import { getIcons, getIconPath } from "@/core/utils/imagePath";

export default defineComponent({
  setup(props, { attrs, emit, slots }) {
    let icons = getIcons();
    let formData = reactive({
      icon: "",
      title: "",
      content: "",
    });

    const getIcon = (name) => {
      return getIconPath(name);
    };

    const submit = () => {
      emit('addFacilitiesAndServices',formData)
    }

    return {
      icons,
      getIcon,
      formData,
      submit
    };
  },
});
